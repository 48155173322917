<template>
  <div class="page-title me-4 mb-3">{{ content.yourReviews }}</div>
  <div class="account-reviews-container" v-if="reviews">
    <div class="mt-12 lg:grid grid-cols-12 w-full review-table-header hidden">
      <div class="col-span-5 review-table-cells">
        {{ content.product }}
      </div>
      <div class="col-span-2 review-table-cells">
        {{ content.date }}
      </div>
      <div class="col-span-4 review-table-cells">
        {{ content.ratingReview }}
      </div>
      <div class="col-span-1 review-table-cells"></div>
    </div>
    <ReviewCard
      v-for="review in reviews"
      :key="review.id"
      :review="review"
    />
  </div>
</template>

<script>
import ReviewCard from "/src/components/ui/Review/ReviewCard.vue";
export default {
  components: { ReviewCard },
  inject: ["content"],
  computed: {
    reviews() {
      return this.$store.getters["reviews/reviews"];
    },
  },
};
</script>