<template>
  <Modal :modalOpened="modalOpened" @close="closeModal">
    <div
      class="
        w-9/10
        sm:w-64
        md:w-80
        h-max-content
        bg-white
        rounded-md
        flex flex-col
        items-center
        p-8
      "
    >
      <p class="text-bluePrimary">
        {{ content.deleteReviewQ }}
      </p>

      <div class="w-full flex items-center justify-center mt-10">
        <button
          class="
            next-tab-button
            header-btn
            hover:text-bluePrimary
            text-white
            bg-bluePrimary
            lora-bold
            whitespace-nowrap
            hover:bg-blueSecondary
            duration-300
            mx-2
          "
          @click="deleteReview"
        >
          {{ content.delete }}
        </button>
        <button class="prev-tab-button mx-2" @click="closeModal">
          {{ content.cancel }}
        </button>
      </div>
    </div>
  </Modal>

  <div class="reviews-card grid grid-cols-12">
    <div class="col-span-12 lg:col-span-5 review-table-cells">
      <div class="review-product-card items-center flex">
        <img :src="review.product.details.image" />
        <div>
          <div class="title ms-4 font-bold">
            {{ review.product.details.title }}
          </div>
          <div class="lora-italic ms-4 italic">
            {{ review.product.collection?.title ?? "" }}
          </div>
        </div>
      </div>
    </div>
    <div class="col-span-2 lg:block hidden review-table-cells">{{ date }}</div>
    <div class="lg:col-span-4 col-span-12 flex flex-col review-table-cells">
      <div class="flex justify-between my-3 lg:my-0">
        <div class="rating text-size-16">
          <div
            class="rating-upper"
            :style="`width:calc( ( ${review.rating} / 5 ) * 100%)`"
          >
            <span><i class="fas fa-star"></i></span>
            <span><i class="fas fa-star"></i></span>
            <span><i class="fas fa-star"></i></span>
            <span><i class="fas fa-star"></i></span>
            <span><i class="fas fa-star"></i></span>
          </div>
          <div class="rating-lower">
            <span><i class="fas fa-star"></i></span>
            <span><i class="fas fa-star"></i></span>
            <span><i class="fas fa-star"></i></span>
            <span><i class="fas fa-star"></i></span>
            <span><i class="fas fa-star"></i></span>
          </div>
        </div>
        <div class="lg:hidden">{{ date }}</div>
      </div>
      {{ review.review }}
    </div>
    <div
      class="
        smd:col-span-1
        col-span-12
        flex
        justify-end
        review-table-cells
        my-4
        lg:my-0
      "
    >
      <button
        @click="openModal"
        class="
          rounded-full
          h-10
          w-10
          grid
          place-content-center
          bg-rejected
          hover:opacity-80
          transition-opacity
          ease-in
          text-white
        "
      >
        <i class="far fa-trash-alt"></i>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["review"],
  data() {
    return {
      modalOpened: false,
    };
  },
  computed: {
    date() {
      return `${this.review.date.split(",")[2]}/${
        this.review.date.split(",")[1]
      }/${this.review.date.split(",")[0]}`;
    },
  },
  inject: ["content"],
  methods: {
    closeModal() {
      this.modalOpened = false;
    },
    openModal() {
      this.modalOpened = true;
    },
    async deleteReview() {
      try {
        await this.$store.dispatch("reviews/deleteReview", this.review.id);
        this.closeModal();
        this.$store.dispatch("feedback/setFeedback", {
          type: "success",
          title: this.content.success,
          message: this.content.reviewDeleted,
        });
      } catch (error) {
        this.$store.dispatch("feedback/setFeedback", {
          type: "fail",
          title: this.content.fail,
          message: this.content.reviewDeleteFail,
        });
      }
    },
  },
};
</script>
